import React, { useState } from "react";
import './Header.scss';
import { Text } from "../Text";
import { useNavigate } from "react-router-dom";
import { IoIosSettings } from "react-icons/io";
import { useTranslation } from "react-i18next";

export const Header = ({ headerRightArea }) => {
  const Navigate = useNavigate();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("formData");
    localStorage.clear()
    navigate("/");
  };

  // Check if token exists in localStorage
  const token = localStorage.getItem("token");
  return (
    <header className="Header flex-shrink-0">
      <div className="LogoContainer" onClick={() => Navigate('/')}>
        <img className="HeaderLogo" src={"../../../picture/logo.png"} alt={'logo'} />
        <Text className={"Text1"} variant={"Heading4"} >SUNNDIO</Text>
        <Text className={"Text2"} variant={"Heading4"}>health</Text>
      </div>
      {headerRightArea && <div className="HeaderRightArea">
        {headerRightArea}
      </div>}
      <div className="mr-4 flex justify-center items-center">
        {/* User Profile Dropdown (visible if token is present) */}
        {token && (
          <div className="relative">
            <div className="flex items-center cursor-pointer justify-center gap-1"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <p className=" text-[18px]">
                {t("userProfile")}
              </p>

              <div className="">
                <IoIosSettings />
              </div>
            </div>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute right-0 mt-0 bg-white rounded-md shadow-lg px-8 py-1 text-[14px] whitespace-nowrap">
                <button className="dropdown-item" onClick={handleLogout}>
                  {t("logout")}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};
